import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { registrationStepsCatalog, IRegistrationDatasource } from '@inwink/registration';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { Entities } from '@inwink/entities/entities';
import type { IFileInputManager } from '@inwink/fileutils/fileapi';
import { logging } from '@inwink/logging';
import { communityFileInputManager } from '@@api/fileapi';
import { IDynamicBlocProps } from '../../../../../components/dynamicpage/common';
import { States } from '../../../../../services/services';
import { wrapReduxStore, IInwinkStore } from '../../../../../store';
import { userMessageModule, communityUserBootstrapModule } from '../../../../../routes/appmodules';
import { RegistrationDatasource } from './registrationdatasource';
import {
    blocMultistepRegistrationData as dynamicBlocMultistepRegistrationData,
    BlocMultistepRegistration as DynamicBlocMultistepRegistration
} from '../../../../../components/dynamicpage/blocs/registration/bloc.multistepregistration';

const datalogger = logging.getLogger("Data");

export interface IBlocMultistepRegistrationState {
}

export function blocMultistepRegistrationData(page: States.ICurrentPageState, blocdata: IBlocMultistepRegistrationState,
    bloc: VisualTheme.IBlocContentTemplateBase, state: States.IAppState, dispatch: (action) => void) {
    return dynamicBlocMultistepRegistrationData(page, blocdata, bloc, state, dispatch);
}

export interface IBlocMultistepRegistrationProps extends IDynamicBlocProps<IBlocMultistepRegistrationState> {
    i18nHelper?: Entities.i18nHelper;
    history?: any;
    store?: IInwinkStore;
    initialData?: {
        registered: any,
        registeredPatch: any
    };
    onRegisteredCreated?: (registered?: any) => void;
}

interface IMultistepState {
    datasource: IRegistrationDatasource;
    fileInputManager: IFileInputManager;
    steps: any[];
    memberFieldTemplate: Entities.IFieldTemplate;
}

class BlocMultistepRegistrationComponent extends React.Component<IBlocMultistepRegistrationProps, IMultistepState> {
    constructor(props: IBlocMultistepRegistrationProps) {
        super(props);

        registrationStepsCatalog.Member = registrationStepsCatalog.Person;
        const datasource = new RegistrationDatasource(props.community, {
            createPerson: {
                contentTemplateId: props.page.template.id,
                blocId: props.template.id,
                personCreated: props.onRegisteredCreated
            }
        });
        const memberFieldTemplate = this.props.community.data.fieldtemplates.data
            .find((f) => f.entityName.toLowerCase() === "member");
        datasource.requireLogin = () => {
            userMessageModule().then((usermsg) => {
                // on utilise le showLogin ici pour ne pas avoir 1 popup en trop #40403
                return usermsg.loginActions.showLogin()(this.props.store.dispatch, this.props.store.getState);
            });
        };

        datasource.showLogin = (afterAuth?) => {
            userMessageModule().then((usermsg) => {
                return usermsg.loginActions.showLogin(afterAuth)(this.props.store.dispatch, this.props.store.getState);
            });
        };
        let steps = [];
        if (this.props.template?.properties?.steps) {
            steps = [...this.props.template.properties.steps];
        }

        this.state = {
            fileInputManager: communityFileInputManager(props.community.requestManagers),
            datasource: datasource,
            steps: steps,
            memberFieldTemplate: memberFieldTemplate
        };
    }

    componentDidUpdate(prevProps: IBlocMultistepRegistrationProps) {
        if (prevProps?.template?.properties?.steps !== this.props.template?.properties?.steps) {
            this.setState({ steps: [...this.props.template.properties.steps] });
        }
    }

    registeredCreated = (registered: any) => {
        if (registered && this.props.user?.currentUser) {
            // on sauvegarde les données du user uniquement si on reçoit l'update depuis l'étape d'envoie des données
            // ça veut dire que les données ont bien été sauvegardées.

            if (this.props.user.currentUser?.member?.id) {
                return communityUserBootstrapModule().then((mod) => {
                    return mod.currentUserActions.checkCurrentUser()(
                        this.props.store.dispatch, this.props.store.getState
                    ).then(() => {
                        return true;
                    });
                });
            } else {
                return communityUserBootstrapModule().then((useractions) => {
                    return useractions.currentUserActions.setCurrentUser(
                        registered
                    )(this.props.store.dispatch, this.props.store.getState);
                }).then(null, (err) => {
                    datalogger.error("error updating user", registered, err);
                });
            }
        }
    };

    render() {
        return <DynamicBlocMultistepRegistration
            {...this.props}
            steps={this.state.steps}
            fileInputManager={this.state.fileInputManager}
            registeredFieldTemplate={this.state.memberFieldTemplate}
            registeredCreated={this.registeredCreated}
            datasource={this.state.datasource}
            defaultPhoneCountryCode={this.props.community?.detail?.configuration?.global?.defaultPhoneCountryCode}
        />;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        user: state.user
    };
}

export const BlocMultistepRegistration: new (any) => React.Component<IBlocMultistepRegistrationProps, any> = connect(
    mapStateToProps,
)(withRouter(wrapReduxStore(BlocMultistepRegistrationComponent) as any)) as any;
