/* eslint-disable @typescript-eslint/no-unused-vars */
import { IOrderItem, IRegistrationDatasource } from '@inwink/registration';
import { Entities } from '@inwink/entities/entities';
import { logging } from '@inwink/logging';
import { checkMailAlreadyExist, createMember } from '@@modules/community/api/member';
import { States } from '../../../../../services/services';
import { getTemplate } from '../../../api/template';
import { trackCommunityError } from '@@modules/community/api/tracking';

const datalogger = logging.getLogger("Data");

interface IRegistrationDatasourceOptions {
    createPerson?: { contentTemplateId: string, blocId: string, personCreated?: (data: any) => void };
}

export class RegistrationDatasource implements IRegistrationDatasource {
    formtemplates: any;

    constructor(private community: States.ICommunityState, private options?: IRegistrationDatasourceOptions) {
    }

    getMe(): Promise<any> {
        throw new Error('Method not implemented.');
    }

    createOrder(paymentMode: string, order: any, discountCodes: string[], buyer: any,
        items: IOrderItem[], returnurl: string, shardTitle: string): Promise<any> {
        throw new Error('Method not implemented.');
    }

    checkOrder(paymentMode: string, order: any, discountCodes: string[], buyer: any,
        items: IOrderItem[], returnurl: string): Promise<any> {
        throw new Error('Method not implemented.');
    }

    updateOrder() : Promise<any> {
        throw new Error('Method not implemented.');
    }

    validateOrder(action: string, orderID: string, orderArguments: any) {
        throw new Error('Method not implemented.');
    }

    checkDiscount(discountCode: string): Promise<any> {
        throw new Error('Method not implemented.');
    }

    checkEmailUnicity(email: string): Promise<any> {
        throw new Error('Method not implemented.');
    }

    getTickets(options?: any, expands?: any): Promise<any> {
        throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    requireLogin(message: string, elt: HTMLElement) {
        throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showLogin(afterAuth?: any, customArgs?: any) {
        throw new Error('Method not implemented.');
    }

    getEntityTemplate(entity: string): Promise<Entities.IEntityTemplate> {
        const name = entity.toLowerCase();
        const template = this.community.data.fieldtemplates.data.filter((e) => e.entityName.toLowerCase() === name)[0];
        if (template) return Promise.resolve(template ? template.template : null);

        return getTemplate(this.community.requestManagers, entity).then(null, (err) => {
            if (!__SERVERSIDE__) {
                trackCommunityError(this.community.requestManagers.apiFront, this.community.communityid,
                    err, "error getting entity template");
            }
            return Promise.reject(err);
        });
    }

    getFormTemplate(): Promise<Entities.IEntityFormTemplate> {
        throw new Error('Method not implemented.');
    }

    checkMailAlreadyExist = (mail: string): Promise<boolean> => {
        return checkMailAlreadyExist(this.community.requestManagers.apiFront, mail);
    };
    
    createPerson(person): Promise<any> {
        const dataInfo = inwink.registration?.dataInfo;
        const sign = inwink.registration?.sign;
        const contentTemplateId = this.options?.createPerson?.contentTemplateId;
        const blocId = this.options?.createPerson?.blocId;
        return createMember(this.community.requestManagers, person, dataInfo, sign, contentTemplateId, blocId)
            .then((res) => {
                if (this.options?.createPerson?.personCreated) {
                    this.options.createPerson.personCreated(res);
                }
                return res;
            }, (err) => {
                datalogger.error("error creating registration", err);
                if (!__SERVERSIDE__) {
                    trackCommunityError(this.community.requestManagers.apiFront,
                        this.community.communityid, err, "error creating registration");
                }
                return Promise.reject(err);
            });
    }

    updateMe(person): Promise<any> {
        const contentTemplateId = this.options?.createPerson?.contentTemplateId;
        const blocId = this.options?.createPerson?.blocId;
        return createMember(this.community.requestManagers, person, null, null, contentTemplateId, blocId).then(null, (err) => {
            datalogger.error("error creating registration", err);
            if (!__SERVERSIDE__) {
                trackCommunityError(this.community.requestManagers.apiFront,
                    this.community.communityid, err, "error creating registration");
            }
            return Promise.reject(err);
        });
    }
}
